

cx-storefront.SearchResultsListPageTemplate {

  cx-page-layout {

    &.SearchResultsListPageTemplate {

      & > cx-page-slot[position="SearchResultsListSlot"] {
        max-width: unset;
      }

    }

  }

}
