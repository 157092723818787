
.material-icons, .material-symbols-outlined {

  &.mat-icon-active:before { content: "\e876"; }
  &.mat-icon-amex:before { content: "\e870"; }
  &.mat-icon-arrow-down-circle:before { content: "\f181"; }
  &.mat-icon-arrow-down:before { content: "\e5db"; }
  &.mat-icon-arrow-left-circle:before { content: "\eaa7"; }
  &.mat-icon-arrow-left:before { content: "\e5c4"; }
  &.mat-icon-arrow-right-circle:before { content: "\eaaa"; }
  &.mat-icon-arrow-right:before { content: "\e5c8"; }
  &.mat-icon-arrow-up-circle:before { content: "\f182"; }
  &.mat-icon-arrow-up:before { content: "\e5d8"; }
  &.mat-icon-bars:before { content: "\e5d2"; }
  &.mat-icon-brush:before { content: "\e3ae"; }
  &.mat-icon-calendar:before { content: "\e878"; }
  &.mat-icon-call:before { content: "\e0b0"; }
  &.mat-icon-cancel:before { content: "\e5c9"; }
  &.mat-icon-caret-down:before { content: "\e5c5"; }
  &.mat-icon-caret-left:before { content: "\e5de"; }
  &.mat-icon-caret-right:before { content: "\e5df"; }
  &.mat-icon-caret-up:before { content: "\e5c7"; }
  &.mat-icon-cart:before { content: "\e8cc"; }
  &.mat-icon-certification:before {content: "\e7af";}
  &.mat-icon-check-circle:before {content: "\e86c";}
  &.mat-icon-circle:before {content: "\ef4a";}
  &.mat-icon-clock:before { content: "\e8b5"; }
  &.mat-icon-close:before { content: "\e5cd"; }
  &.mat-icon-cloud-off:before { content: "\e2c1"; }
  &.mat-icon-cold:before { content: "\f166"; }
  &.mat-icon-collapse:before { content: "\e15b"; }
  &.mat-icon-comment:before { content: "\e0b7"; }
  &.mat-icon-compare-arrows:before { content: "\e915"; }
  &.mat-icon-contracts2:before { content: "\ea3e"; }
  &.mat-icon-contracts:before { content: "\e179"; }
  &.mat-icon-copy:before { content: "\e14d"; }
  &.mat-icon-credit-card:before { content: "\e870"; }
  &.mat-icon-currency:before { content: "\e57d"; }
  &.mat-icon-cut:before { content: "\f08b"; }
  &.mat-icon-delete:before { content: "\e872"; }
  &.mat-icon-diners-club:before { content: "\e870"; }
  &.mat-icon-download:before { content: "\f090"; }
  &.mat-icon-edit:before { content: "\e3c9"; }
  &.mat-icon-empty-heart:before { content: "\e87d"; }
  &.mat-icon-envelope:before { content: "\e0be"; }
  &.mat-icon-error:before { content: "\e000"; }
  &.mat-icon-expand-arrows:before { content: "\f1ce"; }
  &.mat-icon-expand:before { content: "\e145"; }
  &.mat-icon-external:before { content: "\e89e"; }
  &.mat-icon-failure:before { content: "\e8db"; }
  &.mat-icon-file:before { content: "\e24d"; }
  &.mat-icon-filter-off:before { content: "\eb57"; }
  &.mat-icon-filter:before { content: "\e152"; }
  &.mat-icon-first-page:before { content: "\e5dc"; }
  &.mat-icon-flame:before { content: "\f16a"; }
  &.mat-icon-fullscreen:before { content: "\e5d0"; }
  &.mat-icon-fullscreen-exit:before { content: "\e5d1"; }
  &.mat-icon-globe:before { content: "\e80b"; }
  &.mat-icon-grid:before { content: "\e9b0"; }
  &.mat-icon-heart:before { content: "\e87e"; }
  &.mat-icon-help:before { content: "\e8fd"; }
  &.mat-icon-home:before { content: "\e88a"; }
  &.mat-icon-hot:before { content: "\e518"; }
  &.mat-icon-info:before { content: "\e88e"; }
  &.mat-icon-keyboard-arrow-down:before { content: "\e313"; }
  &.mat-icon-keyboard-arrow-left:before { content: "\e314"; }
  &.mat-icon-keyboard-arrow-right:before { content: "\e315"; }
  &.mat-icon-keyboard-arrow-up:before { content: "\e316"; }
  &.mat-icon-last-page:before { content: "\e5dd"; }
  &.mat-icon-link-out:before { content: "\e89e"; }
  &.mat-icon-list:before { content: "\e896"; }
  &.mat-icon-login:before { content: "\ea77"; }
  &.mat-icon-logout:before { content: "\e9ba"; }
  &.mat-icon-master-card:before { content: "\e870"; }
  &.mat-icon-minus-circle:before { content: "\e15d"; }
  &.mat-icon-my-details:before { content: "\ea67"; }
  &.mat-icon-not-interested:before { content: "\e033"; }
  &.mat-icon-off:before { content: "\e9f5"; }
  &.mat-icon-on:before { content: "\e9f6"; }
  &.mat-icon-orders:before { content: "\ef6e"; }
  &.mat-icon-package:before { content: "\f569"; }
  &.mat-icon-paste:before { content: "\ea8e"; }
  &.mat-icon-pdf:before { content: "\e415"; }
  &.mat-icon-pencil:before { content: "\e3c9"; }
  &.mat-icon-plane:before { content: "\e905"; }
  &.mat-icon-plus-circle:before { content: "\e148"; }
  &.mat-icon-plus:before { content: "\e145"; }
  &.mat-icon-price-list:before { content: "\e172"; }
  &.mat-icon-redo:before { content: "\e15a"; }
  &.mat-icon-reset:before { content: "\f053"; }
  &.mat-icon-save:before { content: "\e171"; }
  &.mat-icon-saved-carts:before { content: "\e854"; }
  &.mat-icon-search:before { content: "\e8b6"; }
  &.mat-icon-shiny:before { content: "\e518"; }
  &.mat-icon-ship:before { content: "\e532"; }
  &.mat-icon-sort-down:before { content: "\e5c5"; }
  &.mat-icon-sort:before { content: "\e164"; }
  &.mat-icon-standard-cut-sizes:before { content: "\e14e"; }
  &.mat-icon-star:before { content: "\e885"; }
  &.mat-icon-stock:before { content: "\e1a1"; }
  &.mat-icon-store:before { content: "\e8d1"; }
  &.mat-icon-success:before { content: "\e8dc"; }
  &.mat-icon-thumbs-down:before { content: "\e8db"; }
  &.mat-icon-thumbs-up:before { content: "\e8dc"; }
  &.mat-icon-trash:before { content: "\e872"; }
  &.mat-icon-truck:before { content: "\e558"; }
  &.mat-icon-tune:before { content: "\e429"; }
  &.mat-icon-undo:before { content: "\e166"; }
  &.mat-icon-user:before { content: "\e7fd"; }
  &.mat-icon-verified:before { content: "\ef76"; }
  &.mat-icon-video-tutorials:before { content: "\e63a"; }
  &.mat-icon-visa:before { content: "\e870"; }
  &.mat-icon-warning:before { content: "\f083"; }
}
