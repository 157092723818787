.spinner-loading-overlay {
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.15);

  .overlay-spinner-box {
    --viewWidth: 20vw;
    --viewHeight: 20vh;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: min(var(--viewWidth), var(--viewHeight));
    max-height: min(var(--viewWidth), var(--viewHeight));
    position: sticky;
    top: 160px;
    bottom: 50px;
  }
}
