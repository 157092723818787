@import "@spartacus/styles/scss/core.scss";

@font-face {
  font-family: "Google Font Roboto Reqular";
  src: local("Google Font Roboto Reqular"), url(../../assets/fonts/Roboto/Roboto-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Google Font Roboto Light";
  src: local("Google Font Roboto Light"), url(../../assets/fonts/Roboto/Roboto-Light.ttf) format("truetype");
}

@font-face {
  font-family: "Google Font Roboto Thin";
  src: local("Google Font Roboto Thin"), url(../../assets/fonts/Roboto/Roboto-Thin.ttf) format("truetype");
}

body {

  --kurz-font-family: "Google Font Roboto Reqular", "Roboto", "Open Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --kurz-font-family-light: "Google Font Roboto Light", "Roboto", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  --kurz-font-family-thin: "Google Font Roboto Thin", "Roboto", sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

  --kurz-header-box-shadow: 0px 1px 4px rgba(100 100 100 / 60%);

  --kurz-color-disabled-text: var(--kurz-color-grey-medium);
  --kurz-color-primary: var(--cx-color-primary);
  --kurz-color-secondary: var(--cx-color-secondary);
  --kurz-color-hover-secondary: #DCDCE0;
  --kurz-color-danger: #DC3546;
  --kurz-color-success: var(--cx-color-success);

  // KURZ-Sustainability Green: #5FB334
  --kurz-color-sustainability: #5FB334;

  --kurz-color-grey-dark: #333;
  --kurz-color-grey-medium: #9ea0ac;
  --kurz-color-grey-light: #eaeaef;

  --cx-color-text: var( --kurz-color-grey-dark);
  --kurz-color-text: var( --kurz-color-grey-dark);

  --kurz-color-base-light: #7ec5e8;
  --kurz-color-primary-light: #639cb8;

  --kurz-color-list-hover: #CCE3EF;

  --kurz-color-base-background: #F0F8FE;
  --kurz-color-primary-background: #c9d3db;

  --kurz-color-accent: var(--kurz-color-base-light);
  --kurz-color-info: rgba(214 236 248 / 1);
  --kurz-border-color-accent: var(--kurz-color-primary-light);

  --kurz-color-base: #232967;
  --kurz-color-hover-base: #1c2052;

  --kurz-border-color-secondary: #4c4f55;

  --kurz-color-header: var(--cx-color-text);

  // AppComponent calculates current height of <main>
  --kurz-current-header-height: 200px;

  --kurz-service-page-background-color: #f7f9fa;

  --kurz-header-row1-height: 90px;

  --user-menu-width: 320px;

  --kurz-side-menu-left-width: max-content;
  --kurz-side-menu-right-width: max-content;

  --kurz-header-background: white;

  --kurz-header-z-index: 3;
  --kurz-main-z-index: 1;
  --kurz-footer-z-index: 2;
  --kurz-side-menu-z-index: calc(max(var(--kurz-header-z-index), var(--kurz-main-z-index), var(--kurz-footer-z-index)) + 1);
  --kurz-shop-messages-z-index: calc(var(--kurz-side-menu-z-index) + 1);

  /* Transition Properties */
  --kurz-transition-duration: 500ms;
  --kurz-transition-function: ease;

  --kurz-banner-tile-min-width: 320px;

  --cx-page-margin: calc(((100vw - var(--cx-page-width-max))/2) + 1.5rem);
  --kurz-header-lateral-padding: 30px;

  @include media-breakpoint-down(sm) {
    --kurz-header-row1-height: 70px;
    --kurz-header-lateral-padding: 20px;
  }

  --kurz-shop-message-height: 0px; // set by component

}

