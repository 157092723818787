
// using "media-breakpoint-down" and other mixins
// @import "@spartacus/styles/scss/core.scss";

cx-storefront.NewOrderPageTemplate {

  background-color: var(--kurz-service-page-background-color);

  cx-page-layout {

    &.NewOrderPageTemplate {

      @extend %LoginPageTemplate !optional;

      & > cx-page-slot[position="BodyContent"] {
        display: block;
      }

    }

  }

}
