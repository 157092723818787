
.page-label-quick-order > cx-storefront {

  // parent of KurzQuickOrderHeaderComponent if slot = BottomHeaderSlot
  cx-page-slot[position="BottomHeaderSlot"] {
    position: sticky;
    // top: var(--kurz-current-header-height);

    --_zIndex: calc(var(--kurz-header-z-index, 3) - 1);
    z-index: var(--_zIndex);
  }

  main cx-page-layout {
    display: flex;
    flex-direction: column;

    gap: 2ch;

    & {
      max-width: unset;

      // parent of KurzQuickOrderHeaderComponent if slot = Section1
      & > cx-page-slot[position="Section1"] {
        all: unset;
      }

      // parent of KurzQuickOrderComponent
      & > cx-page-slot[position="Section3"] {
        max-width: var(--cx-page-width-max);
        width: 100%;
        margin: 0 auto;
      }

    }

    // if a page slot is empty (has not any element) then display: none so it wont consider it as an flex item and wont generate extra gap
    & > cx-page-slot:not(:has(*)) {
      display: none;
    }
  }
}
