%cx-carousel {
  display: flex;
  flex: 100%;
  --cx-speed: 0.5;
  flex-direction: column;

  > h2 {
    @include type('3');
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    @include media-breakpoint-up(xl) {
      margin-bottom: 3rem;
    }
  }

  .carousel-panel {
    display: flex;
    justify-content: space-between;

    // TODO: (CXSPA-6904) - Remove feature flag next major release
    @include forFeature('a11yFocusableCarouselControls') {
      align-items: center;
    }

    // The size of carousel items depends on the number of items per slide.
    // We generate 10 sizes in case there are a lot of carousel items displayed
    // on a single slide.
    @for $i from 1 through 10 {
      &.size-#{$i} .item {
        flex: 0 0 calc((100 / #{$i}) * 1%);
      }
    }

    .slides {
      flex: auto;
      position: relative;

      .slide {
        transition: 0.6s all;
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        justify-content: flex-start;

        &:not(.active) {
          // we keep the active slide non-absolute, so the height
          // of the parent is based on the displayed slide
          position: absolute;
          opacity: 0;
          z-index: -1;
          transition: none;
        }

        .item {
          opacity: 0;
          z-index: -1;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          height: 100%;
          &.active {
            opacity: 1;
            z-index: 1;
          }
          transition: 0.4s all;

          // we add a transition delay so that items are nicely animated in a sequence
          @for $i from 1 through 4 {
            &:nth-child(#{$i}) {
              transition-delay: calc(var(--cx-speed, 1) * #{$i * 0.25s});
            }
          }
        }

        // accessibility: to avoid accessing by keyboard tab and VO when item is not visible(opacity: 0) on the screen
        .item {
          visibility: hidden;
        }
        .item.active {
          visibility: visible;
        }
      }
    }
  }

  button {
    &:focus {
      outline: none;

      // TODO: (CXSPA-6904) - Remove feature flag next major release
      @include forFeature('a11yFocusableCarouselControls') {
        @include visible-focus();
      }
    }
    color: var(--cx-color-inverse);

    // TODO: (CXSPA-6904) - Remove all styles with disabled selector
    &:not(:disabled) {
      cursor: pointer;
    }

    @include forFeature('a11yFocusableCarouselControls') {
      &:not(:disabled) {
        cursor: unset;
      }
      &[aria-disabled='false'] {
        cursor: pointer;
      }
    }
  }

  .indicators {
    display: flex;
    justify-content: center;

    button {
      margin: 0;
      transition: 0.6s all;
      border-style: none;
      background-color: var(--cx-color-inverse);
      font-size: var(--cx-font-size, 0.875rem);
      padding: 10px;

      &[disabled] {
        .cx-icon {
          color: var(--cx-color-primary);
          background-color: var(--cx-color-inverse);
          padding: 2px;
        }
      }
      &:not(:disabled):hover {
        color: var(--cx-color-primary-accent);
      }

      .cx-icon {
        color: var(--cx-color-inverse);
        border: 4px solid var(--cx-color-primary);
        border-radius: 20px;
        background-color: var(--cx-color-inverse);
      }

      // TODO: (CXSPA-6904) - Remove feature flag next major release
      @include forFeature('a11yFocusableCarouselControls') {
        &[aria-disabled='true'] {
          .cx-icon {
            color: var(--cx-color-primary);
            background-color: var(--cx-color-inverse);
            padding: 2px;
          }
        }

        &:not([aria-disabled='true']):hover {
          color: var(--cx-color-primary-accent);
        }

        &:focus {
          outline-offset: -5px;
        }
      }
    }

    @include media-breakpoint-only(xs) {
      display: none;
    }
  }

  .previous,
  .next {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    color: var(--cx-color-primary);

    &:disabled {
      opacity: 0.5;
    }

    &:not(:disabled):hover {
      color: var(--cx-color-primary-accent);
    }

    @include forFeature('a11yFocusableCarouselControls') {
      &:not(:disabled):hover {
        color: var(--cx-color-primary);
      }

      &[aria-disabled='true'] {
        opacity: 0.5;
      }

      &[aria-disabled='false']:hover {
        color: var(--cx-color-primary-accent);
      }
    }

    @include media-breakpoint-down(sm) {
      min-width: 48px;
    }
  }

  @include cx-highContrastTheme {
    .indicators {
      button {
        background-color: var(--cx-color-background);
      }
    }

    .indicators button[disabled] .cx-icon {
      color: var(--cx-color-dark);
      background-color: var(--cx-color-light);
      padding: 4px;
    }

    .indicators button[aria-disabled='true'] {
      .cx-icon {
        color: var(--cx-color-dark);
        background-color: var(--cx-color-light);
        padding: 4px;
      }
    }

    .indicators button .cx-icon {
      color: var(--cx-color-light);
      background-color: var(--cx-color-dark);
      border: 3px solid var(--cx-color-dark);
    }
  }
}
