@import '../../mixins/add-eyecatcher-styling-to-page.mixin.scss';

@include add-eyecatcher-styling-to-page("workflow-solutions", "Section1");

.page-label-workflow-solutions > cx-storefront {



  main cx-page-layout {
    & > cx-page-slot {
      display: block;
    }

    & > cx-page-slot[position="Section3"] {
      margin-top: 10ch;
    }
  }
}
