// using "media-breakpoint-down" and other mixins
@import "@spartacus/styles/scss/core.scss";
//custom placeholder selector for body searchbox state
@import "./custom-searchbox-body-styles.scss";

//custom behaviours of the body accoring the searchbox state
body {
  // &.searchbox-is-active {}

  @extend %custom-cx-searchbox__body;
}

// hide the country selector slot in the header if user is not logged in
.authenticated-context {
  cx-storefront {
    & > header > .header {
      grid-template-areas: 'shopmessage shopmessage shopmessage shopmessage shopmessage'
                           'preheader searchbox minicart countryselector logo';

      & > [position="CountrySelector"] {
        // display: none;
        width: 0;
        visibility: hidden;
      }
    }
  }
}

cx-storefront {

  & > header {
    position: sticky;
    top: 0;
    z-index: var(--kurz-header-z-index, 3);
  }

  & > main {
    z-index: var(--kurz-main-z-index, 1);

    // making sure that all content slots in <main> respect the max page width
    // no matter the page template
    & > * {
      max-width: var(--cx-page-width-max);
      margin: auto;
    }

  }

  & > footer {
    z-index: var(--kurz-footer-z-index, 2);
  }

}

// ***************************************************
// <header> .header style
//
cx-storefront > header {
  background: var(--kurz-header-background);

  & > .header {

    color: var(--kurz-color-header, white);
    max-width: unset;

    box-shadow: var(--kurz-header-box-shadow);

    display: grid;
    grid-template-areas: 'shopmessage shopmessage shopmessage shopmessage shopmessage'
                         'preheader searchbox minicart countryselector logo';
    grid-template-rows: repeat(2, min-content);

    @include media-breakpoint-down(sm) {
      grid-template-columns: 0fr 0fr 0fr 0fr auto;
    }

    /* GRID POSITIONING OF SLOTS */
    & > cx-page-slot {

      background-color: transparent;
      justify-self: center;
      align-self: center;

      position: relative;

      &[position="ShopMessage"] {
        grid-area: shopmessage;
        width: 100%;
      }

      &[position="PreHeader"] {
        grid-area: preheader;
        justify-self: start;
        padding: 0.2rem 0;

        .burger-menu-label-container {
          &.closed {
            @include media-breakpoint-down(sm) {
              display: none;
            }
          }
        }
      }

      &[position="SearchBox"] {
        position: relative;
        color: var(--kurz-color-base);
        // the Searchbox needs to have the highest z-index of the elements in the header
        // so that the search results overlaps all header elements
        z-index: calc(var(--kurz-header-z-index, 2) + 1);

        @include media-breakpoint-down(md) {
          position: relative;
          grid-area: searchbox;
        }

        cx-searchbox {
          border: 1px solid currentColor;

          & > label {
            background-color: unset;

            @include media-breakpoint-down(sm) {
              & > input {
                background-color: white;
              }
            }
          }
        }
      }

      &[position="MiniCart"] {
        grid-area: minicart;

        @include media-breakpoint-down(md) {
          margin-right: 2ch;
        }
      }

      &[position="CountrySelector"] {
        grid-area: countryselector;
        width: max-content;
      }

      &[position="CustomSiteLogo"] {
        grid-area: logo;
        width: 100%;
      }


      &[position="NavigationBar"] {
        grid-column: 1 / 13;
        grid-row: 2;
        justify-self: start;
        margin-left: 1ch;
      }
    }
  }

}

// ***************************************************
// <header> .navigation style
//
cx-storefront {
  & > header {
    // & > .navigation {}

    // overriding spartacus style
    // ".is-expanded" references the window being large enough and if it is not large enough
    // then navigation would be only in the hamburger menu but this is not the case in our project
    &:not(.is-expanded) cx-page-layout.navigation {
      display: block;

      //Navigation bar is hidden on mobile
      @include media-breakpoint-down(sm) {
        [position="NavigationBar"], [position="KurzBreadcrumb"] {
          display: none;
        }

      }
    }

  }
}
