@import '../../mixins/add-eyecatcher-styling-to-page.mixin.scss';


.page-label-login {

  /* MAKING SURE WHICH HEADER SLOTS ARE NOT VISIBLE WHILE NOT LOGGED IN */
  &.not-authenticated-context header {
    .header, .navigation {
      & > cx-page-slot {

        &[position="PreHeader"] {
          display: none;
        }

        &[position="SearchBox"] {
          display: none;
        }

        &[position="SiteLogin"] {
          display: none;
        }

        &[position="MiniCart"] {
          display: none;
        }

        &[position="NavigationBar"] {
          display: none;
        }

        &[position="KurzUserRedirect"] {
          display: none;
        }

      }
    }
  }

  // Hidding default global message banner
  cx-global-message {
    display: none;
  }

  cx-storefront {
    >cx-page-slot.BottomHeaderSlot {
      display: none;
    }


    & > footer {
      z-index: var(--kurz-footer-z-index, 2);
    }
  }

  .RightContentSlot {
    display: none;
  }

  cx-asm-main-ui + header {
    position: static; /* Reset position if cx-asm-main-ui is present */

    app-custom-site-logo {
      position: fixed;
      right: 0;
      top: 0;
    }
  }

  cx-asm-main-ui {
    width: 40vw;
  }

  header {
    position: fixed;
    width: 100%;
    background: none;
    pointer-events: none;

    .header {
      box-shadow: none;

      & > [position="CountrySelector"] {
        // display: none;
        width: 0;
        visibility: hidden;
      }

    }

    @include media-breakpoint-down(md) {
      position: relative;
    }
  }

  footer {
    position: fixed;
    width: 100%;
    bottom: 0;

    @include media-breakpoint-down(md) {
      position: relative;
    }
  }

  .LoginPageTemplate main {

    --cx-max-width: 100%;

    cx-page-layout {
      display: grid;
      grid-template-columns: 40vw 60vw;

      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        top: 0;
        position: relative;
        justify-content: flex-end;
      }

      cx-global-message {
        position: absolute;
        z-index: 10;
      }

    }

    cx-spinner {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }


    // cx-page-slot.LeftContentSlot {
    //   width: 40vw;

    //   @include media-breakpoint-down(md) {
    //     width: 100%;
    //     max-width: 450px;
    //   }
    // }

    cx-page-slot.BottomHeaderSlot {
      padding: 0;
      display: block;

      cx-banner {
        height: 100%;
        width: 60vw;
        display: block;
        max-width: unset;
        position: fixed;
        top: 0;
        right: 0;

        img {
          object-fit: fill;
          height: 100vh;
        }

      }

      @include media-breakpoint-down(md) {
        min-height: var(--kurz-header-row1-height);
        max-height: 20vh;
        cx-banner {
          position: relative;
          width: 100%;
          overflow: hidden;

          img {
            height: 100%;
          }

        }
      }
    }

  }
}

// needed to handle the scroll behaviour when the footer overlaps the formular.
@media (min-width: 990px) and (max-height: 1050px) {
  cx-page-slot.LeftContentSlot {
    // padding-top: calc(var(--kurz-header-row1-height) + 2ch) !important;
    padding-bottom: 3rem  !important;
    height: calc(100vh + 750px - calc(100vh - var(--kurz-header-row1-height) - 250px));
  }
}

// @media screen and (min-height: 1051px) {
//   .page-label-login .LoginPageTemplate main cx-page-slot.LeftContentSlot {
//     padding-top: 5rem;
//   }
// }

// ASM form responsiveness
@media (min-width: 990px) and (max-width: 1600px) {
  .page-label-login {
    cx-storefront {
      cx-asm-main-ui {
        cx-asm-main-ui {
          form {
            background-color: deeppink;
            flex-direction: column;
            align-content: center;
            gap: 2ch;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 990px) {
  .page-label-login {
    cx-storefront {
      cx-asm-main-ui {
        width: 100%;
      }
    }
  }
}
