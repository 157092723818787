

// using "media-breakpoint-down" and other mixins
@import "@spartacus/styles/scss/core.scss";

cx-storefront.ProductDetailsPageTemplate {

  cx-page-layout {

    &.ProductDetailsPageTemplate {

      // @extend %ProductDetailsPageTemplate !optional;

      & > cx-page-slot[position="Summary"] {
        display: flex;
        flex-direction: column;
        gap: 2ch;

        // overwriting some pdp styles from composable storefront
        padding-left: 0px;
        padding-right: 0px;

        @include media-breakpoint-down(lg) {
          max-width: unset;
        }

        @include media-breakpoint-up(lg) {
          display: flex;
        }

        @include media-breakpoint-up(xl) {
          max-width: unset;
        }
      }

    }

  }

}
