

cx-storefront.ProductListPageTemplate {

  cx-page-layout {

    &.ProductListPageTemplate {

      & > cx-page-slot[position="ProductListSlot"] {
        max-width: unset;
      }

    }

  }

}
