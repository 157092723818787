
.kurz-tooltip-class {

  position: absolute;
  z-index: 10000;
  padding: 5px 8px;
  border-radius: 3px;
  box-shadow: 5px 2px 5px 0px rgba(0, 0, 0, 0.333);
  background-color: rgb(0, 0, 0);
  color: white;
  font-size: 14px;
  pointer-events: none;
  max-height: 100vh;
  max-width: 100vw;
}
