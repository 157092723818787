

// using "media-breakpoint-down" and other mixins
@import "@spartacus/styles/scss/core.scss";

cx-storefront.PriceListPageTemplate {

  background-color: var(--kurz-service-page-background-color);

  cx-page-layout {

    &.PriceListPageTemplate {

      & > cx-page-slot[position="BodyContent"] {
        display: block;
        // flex-direction: column;
        // gap: 2ch;
        // @include media-breakpoint-up(lg) {
        //   display: flex;
        // }
      }

    }

  }

}
