%cx-card {
  .cx-card-border {
    border: 1px solid var(--cx-color-medium);
  }

  .cx-card-container {
    display: flex;
  }

  .cx-card-label-container {
    flex-grow: 2;
    white-space: pre-line;
  }

  .cx-text-green {
    color: var(--cx-color-success);
    font-weight: var(--cx-font-weight-bold);
  }

  .cx-text-gray {
    color: var(--cx-color-secondary);
  }

  .cx-card-title {
    @include type('4');
    font-weight: var(--cx-font-weight-normal);
    margin-bottom: 0.5rem;
  }

  .cx-card-paragraph {
    padding-inline-start: 0.7em;

    .cx-card-paragraph-title {
      padding-top: 0.7em;
    }
  }

  .cx-card-fit-to-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .cx-card-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .cx-card-delete {
    background-color: var(--cx-color-background);
  }

  .cx-card-body-delete {
    padding: 1rem 0 0 0;

    .btn-secondary {
      @include media-breakpoint-down(sm) {
        margin: 0 0 1rem;
      }
    }
  }

  .cx-card-delete-msg {
    color: var(--cx-color-danger);
    padding: 0 0 1.25rem 0;
  }

  .cx-card-actions {
    display: flex;
    justify-content: flex-end;
    padding: 1.25rem 0 0 0;
  }

  .cx-card-link {
    margin-inline-start: 1rem;
  }

  button.link {
    margin-inline-start: 1rem;
  }

  @include cx-highContrastTheme {
    .cx-card {
      padding: 2px;
    }
    .card-body {
      background-color: var(--cx-color-background);
    }
  }
}
