
/*
It's possible to write styles that are specific to a single page due to the fact
that the AppComponent adds a unique css class "page-label-{{label of cms page}}"
*/
@import './explicit-page-styles/homepage.scss';
@import './explicit-page-styles/workflow-solutions.scss';
@import './explicit-page-styles/login.scss';
@import './explicit-page-styles/reset-password.scss';
@import './explicit-page-styles/quick-order.scss';
@import './explicit-page-styles/recosys.scss';

@import './explicit-template-styles/landing-page-2-template.scss';
@import './explicit-template-styles/new-order-page-template.scss';
@import './explicit-template-styles/product-details-page-template.scss';
@import './explicit-template-styles/price-list-page-template.scss';
@import './explicit-template-styles/account-page-template.scss';
@import './explicit-template-styles/cart-page-template.scss';
@import './explicit-template-styles/checkout-review-order-page-template.scss';
@import './explicit-template-styles/order-confirmation-page-template.scss';
@import './explicit-template-styles/product-list-page-template.scss';
@import './explicit-template-styles/search-results-list-page-template.scss';

@import './explicit-component-styles/custom-cx-generic-link-style.scss';
@import './explicit-component-styles/custom-cx-media-style.scss';
@import './explicit-component-styles/custom-header-style.scss';
@import './explicit-component-styles/custom-footer-style.scss';
@import './explicit-component-styles/cx-asm-main-ui-style.scss';
@import './explicit-component-styles/spinner-overlay-style.scss';
