
@import "@spartacus/asm/styles/components/_asm-bind-cart-dialog.component.scss";
@import "@spartacus/asm/styles/components/_asm-main-ui.component.scss";
@import "@spartacus/asm/styles/components/_asm-toggle-ui.component.scss";
@import "@spartacus/asm/styles/components/_asm-session-timer.component.scss";
@import "@spartacus/asm/styles/components/_customer-selection.component.scss";
@import "@spartacus/asm/styles/components/_customer-emulation.component.scss";
@import "@spartacus/asm/styles/components/_asm-bind-cart.component.scss";

cx-storefront {

  cx-asm-main-ui {
    cx-csagent-login-form {
      form {
        padding: 1ch 2ch;
        display: flex;
        align-items: baseline;
        gap: 2ch;
      }
    }

    cx-asm-toggle-ui {
      @extend %cx-asm-toggle-ui;
    }

    cx-asm-session-timer {
      @extend %cx-asm-session-timer;
    }

    cx-customer-selection {
      form {
        label {
          min-width: 15rem;
        }
      }

      .asm-results {
        width: auto;
      }

      @extend %cx-customer-selection;
    }

    cx-customer-emulation {
      @extend %cx-customer-emulation;
    }

    cx-asm-bind-cart {
      @extend %cx-asm-bind-cart;
    }

    @extend %cx-asm-main-ui;
  }
}

@media screen and (max-width: 600px) {
  cx-storefront {
    cx-asm-main-ui {
      form {
        flex-direction: column;
        align-content: center;
        justify-content: center;
        gap: 2ch;
      }
    }
  }
}
