
// using "media-breakpoint-down" and other mixins
@import "@spartacus/styles/scss/core.scss";

cx-storefront.LandingPage2Template {

  cx-page-slot {

    &.Section3.Section3 {
      max-width: var(--cx-page-width-max);
    }

    &.Section4 {

      & > * {
        @include media-breakpoint-down(md) {
          border-bottom: unset;
          border-inline-end: unset;
        }
      }
    }

  }

}
