
/*
  Added to the JSON-Object in the angular.json.

  1.
  projects.{your_procet_name}.architect.build.options.styles: [
    ...,
    "{rel._path_to_this_folder}/util/_index.scss"
  ],

  2.
  projects.{your_procet_name}.architect.test.options.styles: [
    ...,
    "{rel._path_to_this_folder}/util/_index.scss"
  ],
*/

@import 'directives/kurz-tooltip/kurz-tooltip.directive.scss';
