@import '../../mixins/add-eyecatcher-styling-to-page.mixin.scss';

@include add-eyecatcher-styling-to-page("login", "Section1");


.page-label-login-pw-change {

  /* MAKING SURE WHICH HEADER SLOTS ARE NOT VISIBLE WHILE NOT LOGGED IN */
  &.not-authenticated-context header {
    .header, .navigation {
      & > cx-page-slot {

        &[position="PreHeader"] {
          display: none;
        }

        &[position="SearchBox"] {
          display: none;
        }

        &[position="SiteLogin"] {
          display: none;
        }

        &[position="MiniCart"] {
          display: none;
        }

        &[position="NavigationBar"] {
          display: none;
        }

        &[position="CustomSiteLogo"] {
          display: none;
        }

      }
    }
  }

  cx-storefront {
    > main > * {
      max-width: unset;
    }

    >cx-page-slot.BottomHeaderSlot {
      display: none;
    }

    cx-asm-main-ui + header {
      position: static; /* Reset position if cx-asm-main-ui is present */

      app-custom-site-logo {
        position: fixed;
        right: 0;
        top: 0;
      }
  }

  cx-asm-main-ui {
    width: 40vw;
  }
  }

  header {
    position: fixed;
    width: 100%;
    background: none;

    .header {
      box-shadow: none;

      & > [position="CountrySelector"] {
        // display: none;
        width: 0;
        visibility: hidden;
      }
    }

    @include media-breakpoint-down(md) {
      position: relative;
    }
  }

  footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: var(--kurz-footer-z-index, 2);

    @include media-breakpoint-down(md) {
      position: relative;
    }
  }

  .LoginPageTemplate main {

    cx-page-layout {
      display: grid;
      grid-template-columns: 40vw 60vw;

      @include media-breakpoint-down(md) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column-reverse;
        top: 0;
        position: relative;
        justify-content: flex-end;
      }

      cx-global-message {
        position: absolute;
        z-index: 10;
      }
    }

    cx-spinner {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 10;
    }


    cx-page-slot.LeftContentSlot {
      justify-content: flex-start;

      @include media-breakpoint-down(sm) {
        width: 100%;
        max-width: 450px;
      }

      // needed to handle the scroll behaviour when the footer overlaps the formular.
      @media (min-width: 990px) and (max-height: 930px) {
        padding-bottom: 3rem  !important;
        height: calc(100vh + 650px - calc(100vh - var(--kurz-header-row1-height) - 250px));
      }

    }

    cx-page-slot.RightContentSlot {
      padding: 0;
      display: block;

      cx-banner {
        height: 100%;
        width: 60vw;
        display: block;
        max-width: unset;
        position: fixed;
        top: 0;
        right: 0;

        img {
          object-fit: cover;
          height: 100vh;
        }

      }

      @include media-breakpoint-down(md) {
        min-height: var(--kurz-header-row1-height);
        cx-banner {
          position: relative;
          width: 100%;
          overflow: hidden;

          img {
            height: 100%;
          }

        }
      }
    }

    cx-page-slot.BottomHeaderSlot {
      display: none;
    }

  }
}


