@import "@spartacus/styles/scss/core.scss";
@import "@spartacus/styles/scss/components/product/search/searchbox";

// Custom Placeholder selector to adjust the behaviour of the mapped searchbox according to clickdummy.
%custom-cx-searchbox__body {
  // hide the mobile search button by default and how on mobile
  button.search {
    @include media-breakpoint-up(md) {
      display: none;
    }
  }
  // hide the search icon on mobile
  div.search-icon {
    @include media-breakpoint-down(sm) {
      display: none;
    }
  }

  // when there are results and the searchbox is active
  // we display the searchbox
  &.searchbox-is-active {

    &.has-searchbox-results {
      app-custom-searchbox {

        .reset {
          display: flex;
        }

        .results {
          display: block;
          color: var(--kurz-color-base);
          border: 1px solid currentColor;
          border-top: none;
        }
      }

    }

    // the searchbox requires an overlay over the page (except for the header)
    header {
      position: relative;
      z-index: 100;

      // additional overlay due to position of navigation component
      app-kurz-category-navigation{
        &:before {
          content: '';
          position: absolute;
          width: 100%;
          height: 40px;
          left: 0;
          z-index: 1;
          background: rgba(0, 0, 0, 0.5);
        }
      }
    }

    cx-storefront {
      &:before {
        content: '';
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;

        background: rgba(0, 0, 0, 0.5);
      }
    }


    app-custom-searchbox {
      position: absolute;
      top: 10px;
      width: 45vw;
      height: 56px;
      max-height: 700px;
      z-index: 10;

      label, cx-searchbox label {
        width: 100%;
        height: 100%;
        max-width: unset;
        border: 1px solid currentColor;
      }

      button.search {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        width: 100vw;

        label.searchbox {
          padding: 0;

          input {
            position: relative;
            top: unset;
            border: none;
          }
        }

        .results {
          top: unset;
        }

        button.reset {
          cx-icon {
            left: unset;
            top: unset;
          }
        }

      }
    }

  }

  &:not(.searchbox-is-active) {
    app-custom-searchbox {
      button.reset {
        display: none;
      }

      @include media-breakpoint-down(sm) {
        label.searchbox input {
          display: none;
        }
      }
    }

    @include media-breakpoint-down(sm) {
      // hide the input on small screens if there's no interaction with searchbox
      app-custom-searchbox {
        input {
          // we cannot use display:none, visible:hidden or opacity: 0
          // as this will no longer emit a focus event to the controller logic
          width: 0;
          height: 0;
          pointer-events: none;
          padding: 0;
        }
        .search-icon {
          display: none !important;
        }

        button.reset {
          display: none;
        }

        label.searchbox {
          background-color: unset;
          padding: 1ch 0 0 0;
        }

      }
    }
  }
}

app-custom-searchbox {
  border: none;
  transition: all 1s ease;

  @extend %cx-searchbox;
}

body.searchbox-is-active {

  app-root {

    header {

      cx-page-layout[section="header"] {

        cx-page-slot[position="SearchBox"] {
          // if searchbox is open then make sure it is centered
          app-custom-searchbox {
            position: fixed;
            inset: 0;
            top: 60px;
            margin: 0 auto;
          }
        }

      }

      cx-page-slot[position="MiniCart"] {
        visibility: hidden;
      }

      cx-page-slot[position="CountrySelector"] {
        visibility: hidden;
      }

    }

  }

}

