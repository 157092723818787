

cx-storefront.OrderConfirmationPageTemplate {

  background-color: var(--kurz-service-page-background-color);

  cx-page-layout.OrderConfirmationPageTemplate {

    // --cx-page-width-max: 1280px;

    & > cx-page-slot[position="BodyContent"] {
      background-color: transparent;
    }

  }

}
