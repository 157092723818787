
@import '../../mixins/add-eyecatcher-styling-to-page.mixin.scss';

@include add-eyecatcher-styling-to-page("homepage", "Section1");

@include add-eyecatcher-styling-to-page("homepage", "Section5");

.page-label-homepage > cx-storefront {

  --_cmp-gap: 6ch;

  main cx-page-layout {
    display: flex;
    flex-direction: column;

    gap: var(--_cmp-gap);

    // if a page slot is empty (has not any element) then display: none so it wont consider it to be an flex item and wont generate extra gap
    & > cx-page-slot:not(:has(*)) {
      display: none;
    }

    & > cx-page-slot[position="Section3"] {
      display: flex;
      flex-direction: column;

      gap: var(--_cmp-gap);

      margin: unset;
      margin-top: var(--_cmp-gap);

      align-self: center;
      width: 100%;
      overflow: hidden;

      // is there a banner component (highly animated) in Section3 then there will be extra space
      & > cx-banner {
        margin: 6ch 0;
      }

    }
  }
}
