$styleVersion: 2211.27;


// overriding spartacus css custom properties
@import './styles/last/override-css-custom-properties.scss';

// creating kurz css custom properties
@import './styles/last/set-kurz-custom-properties.scss';

@import './app/custom/styles/index.scss';

@import './styles/last/google-material-font-icon-classes.scss';

@import './app/util/index';

html {

  scroll-behavior: smooth;

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    opacity: 1;
  }

  body {
    font-family: var(--kurz-font-family);
    // font-family: var(--kurz-light-font-family);
    color: var(--kurz-color-text);

    &.modal-open {
      overflow: hidden !important;
    }

  }

  ul {
    list-style-type: disc;
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;
  }

}
