

// using "media-breakpoint-down" and other mixins
@import "@spartacus/styles/scss/core.scss";

cx-storefront.AccountPageTemplate {

  background-color: var(--kurz-service-page-background-color);

  main {

    cx-page-layout {

      cx-page-slot {

        // reset spartacus' login-page.scss rules which influences AccountPageTemplate
        padding-inline-start: unset;
        padding-inline-end: unset;
        padding-top: unset;

      }

    }

  }

}
