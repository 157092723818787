// using "media-breakpoint-down" and other mixins
@import "@spartacus/styles/scss/core.scss";

/* You can add global styles to this file, and also import other style files */


/* list of sections, components */
$skipComponentStyles: (
  cx-mini-cart,
  cx-header,
  header,
  _header,
  footer,
  cx-footer,
  Footer
);


@import './styles-config.scss';
@import '@spartacus/styles/index';


// if there is a <cx-product-references>, that has an empty <cx-carousel> then do not display this <cx-product-references> (hides unnecessary space)
cx-product-references {
  &:has(cx-carousel:empty) {
    display: none;
  }
}

// cancels the style rule of <a> elements to receive a min-height, min-width if viewport is smaller or equal "sm"
a {

  @include media-breakpoint-down(sm) {
    min-height: unset;
    min-width: unset;
    display: unset;
  }

}
