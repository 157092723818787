body {

  /* main colors */
  --cx-color-background: #cccccc;
  --cx-color-primary: #232967;
  --cx-color-secondary: #EAEAEF;
  --cx-color-light: #d3d6db;

  --cx-color-success: #198754;
  --cx-color-danger: #dc3545;

  --cx-color-visual-focus: #6d9df7;

  --cx-color-background-focus: rgba(80, 176, 244, 0.1);

  --cx-page-width-padding: 0ch;
  --cx-page-width-max: calc(1280px - var(--cx-page-width-padding));

  @media screen and (width <= calc(1280px + 4ch)) {
    --cx-page-width-padding: 6ch;
    --cx-page-width-max: calc(100dvw - var(--cx-page-width-padding));

    @media screen and (orientation: portrait) {
      --cx-page-width-padding: 4ch;
    }
  }

  --cx-buttons-border-radius: 0px;

  --cx-buttons-border-radius: 0;
}
