
// using "media-breakpoint-down" and other mixins
// @import "@spartacus/styles/scss/core.scss";

@mixin add-eyecatcher-styling-to-page($page-label, $eye-catcher-section: "Section1") {

  /* the AppComponent adds a class to <app-root> with "page-label-" + the label of the current page */
  .page-label-#{$page-label} {

    & > cx-storefront {

      main cx-page-layout {
        /* negate preset values
          (later we can set a max-width for every single cx-page-slot,
          who needs to increase their readability)
        */
        display: block;
        max-width: unset;
        & > cx-page-slot {
          /* the slot with the eyecatcher */
          &[position="#{$eye-catcher-section}"] {
            position: relative;
            overflow: hidden; /* TODO: should be 'overflow: hidden' used in every cx-page-slot? */
            width: inherit; /* Avoids braking the max with on handhels*/
            overflow: hidden;

            // negate preset values
            max-width: unset;
            margin: unset;
            margin-top: unset;

          }
        }
      }
    }

  }

}
