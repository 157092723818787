
// --kurz-color-base: #004a9f;
// --kurz-color-primary: #00397a;

cx-generic-link {
  a {
    color: var(--kurz-color-base);
    &:hover {
      text-decoration: none;
      color: var(--kurz-color-base);
      // filter: brightness(1.6);
    }
  }

  &.kurz-style {
    a {
      transition-property: filter;
      transition-duration: 250ms;
      transition-timing-function: ease;
      &:hover {
        filter: brightness(1.6);
      }
    }
  }

}
